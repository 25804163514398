body {
    font-family: 'Anonymous Pro', monospace;
    background-color: #1a1a1a;
}
@keyframes zoom {
	0% {
		background-position: 50% 100%;
	}

	100% {
		background-position: 50% 0%;
	}
}
.main-wrapper {
    height: 100vh;
    width: 100vw;
    background-image: url('../images/street.jpg');
    background-size: auto;
    background-position-y: 45%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: zoom 3s ease 0s 1 normal forwards;

}
@keyframes zoommonitor {
	0% {
		transform: translateY(-1000px);
	}

	100% {
		transform: translateY(0);
	}
}

.monitor {
    height: 86%;
    width: 88%;
    border: 24px solid #141414;
    border-radius: 8px;
    background-image: linear-gradient(to left top, #1a1a1a, #161616);
    z-index: 3;
    box-shadow: inset 8px 8px 8px #0c0c0ccc, inset -8px -8px 8px #79797910;
    animation: zoommonitor 3s ease 0s 1 normal forwards;
}

.stand {
    width: 40px;
    height: 100px;
    background-color: #141414;
    z-index: 3;
    box-shadow: inset 8px 0px 8px #0f0f0f77;
    margin-bottom: 32px;
    animation: zoommonitor 3s ease 0s 1 normal forwards;
}
@keyframes zoompole {
	0% {
        transform: scaleY(180%);
        transform-origin: top;

	}

	100% {
        transform: scaleY(90%);
        transform-origin: bottom;


	}
}
.pole {
    width: 80px;
    height: 1100px;
    background-color: #141414;
    z-index: 1;
    box-shadow: inset 8px 0px 16px #0f0f0f77, inset -8px 0px 16px #0f0f0f77;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    animation: zoompole 3s 0s 1 normal forwards;

}

.overlay {
    /* position:static; */
    height: 100%;
    width: 100%;

    background: linear-gradient(to top, #00000033, #00000033, #1d1d1d2c, #1d1d1d2c);
    background-size: cover;
    background-size: 100% 4px;
    /* z-index: 1000; */
    /* pointer-events: none; */
    
}

.frame {
    background: radial-gradient(at center top, #ffffff0e, #e9e9e904);    
    height: 94%;
    /* width: 100%; */
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 48px;
}

.frame-left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}

.type{
    animation: typewriter 3s 1s steps(24);
    &:after{
      content: "|";
      animation: blink 500ms linear infinite alternate;
    }
}

@keyframes typewriter{
    from
    {
        width: 0;
    }
    to
    {
        width: 100%;
    }
}

@-webkit-keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

@-moz-keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}

@keyframes blink{
  0%{opacity: 0;}
  100%{opacity: 1;}
}
/* .left-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
} */

.right-content {
    width: 88%;
    height: 96%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.right-content a {
    margin: 8px;
}

.right-content a:hover {
    transform: translateY(2px);
    cursor: pointer;
}

.topNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-right: 0px; */
}

.frame-right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

@keyframes subcontentfadein {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.sub-content {
    height: max-content;
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    animation: subcontentfadein 2s ease 0s 1 normal forwards;
}

.internalsub-content {
    margin-top: 16px;
    margin-bottom: 16px;
}

.scrollingsubcontent {
    /* height: 100%; */
    margin-top: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
}

.scrollingsubcontent hr {
    width: 88%;
    margin-right: 12%;
}

/* .pfp {
    height: 300px;
    width: 300px;
    background-image: url('../images/pfp.jpg');
    background-size: 100%;
    border: none;
    border-radius: 100%;
} */

.for-hire {
    font-size: 32px;
    background-image: linear-gradient(to right, #cbd3d4, #c1c6c7);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtext {
    width: 60%;
}

.navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
}

.navbar-items-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: #1a1a1a77; */
    /* backdrop-filter: blur(16px); */
    /* box-shadow: 0px 8px 0px #181818; */
    border: none;
    /* border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; */
    border-radius: 16px;

    padding: 14px;
    z-index: 1;
    padding-left: 0px;
}

.navbar-social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.navbar-social a {
    /* box-shadow: 0px 8px 0px #201914; */
    border: none;
    border-radius: 16px;
    /* background-color: #1d1d1d; */
    

    margin-left: 2px;
    margin-right: 2px;
    padding-top: 2px;
    
}

.navbar-social a:hover {
    transform: translateY(2px);
    /* box-shadow: inset 8px 8px 2px #161616; */
    /* box-shadow: 0px 4px 0px #1a1410; */
    border: none;
    border-radius: 16px;

}

.navbar-social svg {
    margin-left: 4px;
    margin-right: 4px;
    stroke: #cbd3d4;

}

.navbar-social svg:hover {
    stroke: #D5CCBD;

}

strong {
    background-image: linear-gradient(to right, #D5CCBD, #c5bdaf);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a {
    text-decoration: none;
    background-image: linear-gradient(to right, #D5CCBD, #c5bdaf);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* .projectTitle {
    cursor: pointer;
} */

.intro-large {
    font-size: 56px;
    background-image: linear-gradient(to right, #D5CCBD, #c5bdaf);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
}

.intro-medium {
    font-size: 48px;
    background-image: linear-gradient(to right, #6F8287, #637479);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro-small {
    font-size: 32px;
    background-image: linear-gradient(to right, #778386, #6d7679);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro-smaller {
    font-size: 24px;
    background-image: linear-gradient(to right, #cbd3d4, #c1c6c7);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
}

.intro-smaller.active {
    font-weight: bold;
    background-image: linear-gradient(to right, #D5CCBD, #c5bdaf);

  }

.intro-smallest {
    font-size: 20px;
    background-image: linear-gradient(to right, #cbd3d4, #c1c6c7);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background-color: rgba(0, 0, 0, 0.199);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 0.5s ease forwards;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modalContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 16px;
}


@media (max-width: 1700px) {

    .frame {
        display: flex;
        /* flex-direction: column; */
        /* justify-content: flex-start; */
        /* align-items: flex-start; */
    }
    .frame-left {
        display: flex;
        flex-direction: column; 
        width: 96%;
    }
    /* .overlay {
        width: 81%;
    } */
    .subtext {
        width: 100%;
    }
    .right-content {
        display: none;
    }
    .intro-large {
        white-space: wrap;
        overflow: hidden;
        animation:none;
    }
}

@media (max-width: 500px) {
    .frame-left {
        justify-content: space-between;
    }
    .intro-large {
        white-space: wrap;
        overflow: hidden;
        animation:none;
        font-size: 42px;
    }
    /* .overlay {
        width: 78%;
        height: 96%;
    }
        */
    .frame {
        padding-left: 16px;
    }
    .right-content {
        display: none;
    }
    .intro-medium {
        font-size: 32px;
    }
    .intro-small {
        font-size: 20px;
    }
    .intro-smaller {
        font-size: 18px;

    }
    .subtext {
        width: 93%;
    }
    .main-wrapper {
        flex-direction: column;
        align-items:  center;
        background-position-y: 90%;
        animation: none;

    }
    .pole {
        height: 100px;
        transform: translateY(0px);
        animation: none;

    }
    .stand {
        height: 40px;
        margin-bottom: 0px;
        animation: none;

    }
    .monitor {
        animation: none;

    }
}

@media (max-width: 420px) {
    .intro-large {
        white-space: wrap;
        overflow: hidden;
        animation:none;
        font-size: 42px;
    }
    .monitor {
        margin-top: 1em;
    }
    /* .overlay {
        width: 84%;
        height: 86%;
    } */
    /* .frame {
        width: 87%;
    } */
    /* .right-content {
        width: 110%;
    } */
    .intro-medium {
        font-size: 32px;
    }
    .intro-small {
        font-size: 20px;
    }
    .intro-smaller {
        font-size: 18px;

    }
}